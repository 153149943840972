import {createStore, Store} from 'vuex';

interface RootState {
}

const store: Store<RootState> = createStore({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {

    }
});

export default store;
