import {createApp} from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import '@/permission';
import '@/styles/style.css';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'; // 确保导入 Element Plus 的样式


const app = createApp(App)

app.use(ElementPlus);
app.use(store);
app.use(router);
app.mount('#app')
