import router from './router'

// 前置守卫
router.beforeEach(async (to, from, next) => {
    const {title, isAuth} = to.meta
    document.title = title + ''
    const token = localStorage.getItem('token')
    if (isAuth && !token) return next({path: '/login'})
    if (token && to.path === '/login') return next({path: '/'})
    next()
})

// 后置守卫
router.afterEach(() => {

})
